.status-badge {
  height: 5px;
  width: 25px;
  margin-right: 5px; }

.iframeStyle {
  width: 1110px;
  height: 1500px;
  -webkit-transform: scale(0.12);
  -moz-transform: scale(0.12);
  -o-transform: scale(0.12);
  transform: scale(0.12);
  position: absolute;
  left: -350px;
  top: -480px; }

.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #a8dbda !important;
  cursor: pointer !important; }

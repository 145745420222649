.inputDnD .form-control-file {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 6em;
  outline: none;
  visibility: hidden;
  cursor: pointer;
  background-color: #c61c23;
  box-shadow: 0 0 5px solid currentColor; }

.inputDnD .form-control-file:before {
  content: attr(data-title);
  position: absolute;
  top: 0.5em;
  padding-top: 14%;
  left: 0;
  width: 100%;
  min-height: 6em;
  line-height: 2em;
  opacity: 1;
  visibility: visible;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden; }

#camera-body,
#reel-1,
#reel-2 {
  fill: #fff;
  fill-opacity: 0.95;
  stroke: none; }

.pageloader {
  position: fixed;
  z-index: 1700;
  top: 4rem;
  left: 0;
  background-color: #162a35;
  background-color: #162a35eb;
  height: 100%;
  width: 100%; }

.pageloader span {
  font-family: 'Open Sans', arial, sans-serif;
  font-size: 1.2em;
  font-weight: lighter;
  font-weight: 300;
  line-height: 2em;
  color: #fff; }

/*................Mobile............*/
@media only screen and (max-width: 992px) {
  .pageloader {
    top: 3.3rem; } }

/*............My Screen ...................*/
.table-header {
  background-color: #66CBC9;
  height: 3rem;
  color: black;
  font-size: smaller;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600; }

.blue-gradient {
  background-image: linear-gradient(to right, #66CBC9, #9BD9D9);
  color: white; }

.row-striped {
  background-color: #fff;
  height: 3rem;
  margin-bottom: 5px;
  transition: background 0.3s; }

.row-striped:hover {
  background-color: #B8DCE100; }

/*...........................*/
.ag-header-cell {
  display: inline-block;
  height: 100%;
  position: absolute;
  vertical-align: bottom;
  background-color: #66CBC9;
  /* color:white; */
  color: black;
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  /* font-weight:200; */
  font-weight: 600; }

.ag-cell {
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px; }

.facebook-button {
  font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: inline-block;
  font-size: 18px;
  padding: 13px 30px 15px 44px;
  background: #3A5A97;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 20, 0.4);
  text-decoration: none;
  line-height: 1;
  position: relative;
  border-radius: 5px;
  cursor: pointer; }

.socialicons {
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0px 0px 2px #888;
  /*padding: 0.5em;*/
  padding: 0.15em 0.4em;
  background: #fff;
  color: #3A5A97;
  font-size: 20px; }

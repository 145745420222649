.video_thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: fill; }

.scale {
  cursor: pointer;
  transition: all 0.3s ease; }

.scale:hover {
  transform: scale(1.05); }

.video_thumbnail_title {
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 35px;
  width: 100%;
  min-width: 150px;
  background: #162a35;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1; }

.video_thumbnail_title > span {
  color: #00bcd4;
  font-size: 12px;
  padding: 5px; }

ol.progtrckr {
  list-style-type: none;
  padding: 0; }

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer; }

ol.progtrckr li span {
  padding: 0 1.5rem; }

@media (max-width: 650px) {
  .progtrckr li span {
    display: none; } }

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem; }

@media (max-width: 650px) {
  .progtrckr em {
    display: inline; } }

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver; }

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #CCCCCC; }

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c; }

ol.progtrckr li:after {
  content: "\A0\A0"; }

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%; }

ol.progtrckr li.progtrckr-todo:before {
  content: "\39F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em; }

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500; }

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #CCCCCC;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em; }

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500; }

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em; }

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333; }

.selectedAdCard {
  border: 1px solid #007bff; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append, .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.day-cell {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat; }

.training-background-1 {
  background-color: rgba(86, 202, 133, 0.1); }

.training-background-2 {
  background-color: rgba(255, 151, 71, 0.1); }

.fade-in {
  animation-name: fadeIn;
  animation-duration: 250ms;
  animation-fill-mode: forwards; }

.fade-out {
  opacity: 0; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

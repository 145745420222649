@charset "UTF-8";
.save-icon {
  position: absolute;
  top: 45%;
  left: 45%;
  background: white;
  height: 60px;
  width: 55px;
  display: block;
  padding-top: 10px;
  border: 1px solid;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.save-icon:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 10px 10px 0;
  border-style: solid;
  border-color: #a4a6a7 #3c4145; }

.save-icon.saved:after {
  content: "\2713";
  color: green;
  font-size: 30px;
  position: absolute;
  top: 15%;
  left: 20%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  -webkit-animation: pop 0.5s .3s forwards;
  -moz-animation: pop 0.5s .3s forwards;
  animation: pop 0.5s .3s forwards; }

.loader {
  background: #e2e2e2;
  width: 80%;
  height: 5px;
  display: block;
  margin: 3px auto;
  position: relative;
  overflow: hidden; }

.saved .loader {
  -webkit-animation: fade-loaders 0.2s .3s forwards;
  -moz-animation: fade-loaders 0.2s .3s forwards;
  animation: fade-loaders 0.2s .3s forwards; }

.loader:after {
  content: "";
  background: #2c3033;
  width: 0;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0; }

.loader:first-child:after {
  -webkit-animation: loader 1s infinite;
  -moz-animation: loader  1s infinite;
  animation: loader  1s infinite; }

.loader:nth-child(2n):after {
  -webkit-animation: loader  1.5s infinite;
  -moz-animation: loader  1.5s infinite;
  animation: loader  1.5s infinite; }

.loader:nth-child(3n):after {
  -webkit-animation: loader  2s infinite;
  -moz-animation: loader  2s infinite;
  animation: loader  2s infinite; }

@-webkit-keyframes loader {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@-moz-keyframes loader {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@keyframes loader {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@-webkit-keyframes pop {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1); } }

@-moz-keyframes pop {
  0% {
    -moz-transform: scale(0); }
  100% {
    -moz-transform: scale(1); } }

@keyframes pop {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes fade-loaders {
  0% {
    opactity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fade-loaders {
  0% {
    opactity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-loaders {
  0% {
    opactity: 1; }
  100% {
    opacity: 0; } }

/*..................Custom Styling..............*/
html, body {
  height: 100%; }

body, html,
h1,
h3,
.h1,
.h3 {
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.bg-dark {
  background-color: #162A35 !important; }

.navbar .navbar-nav .nav-link {
  color: #9a9db7;
  height: 4rem; }

.navbar .navbar-nav > li > a > i {
  color: #525672; }

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

/*......................................*/
/*.................General Navbar Config.....................*/
.navbar {
  transition: width 400ms;
  height: 4rem;
  z-index: 1500;
  width: 100%;
  position: fixed; }

.icon-border {
  margin-left: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  border: 1px solid #868aa8;
  justify-content: center; }

.navbar .navbar-nav > .nav-item > .nav-link:not(.user-avatar) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.navbar .navbar-nav > .nav-item > .nav-link:not(.user-avatar) i {
  width: 30px;
  font-size: 1rem; }

.navbar .navbar-nav > .nav-item > .nav-link:not(.user-avatar) span {
  font-size: 1rem; }

.navbar .navbar-nav > .nav-item > .nav-link {
  text-align: center;
  font-size: 0.8rem;
  -webkit-transition: color .3s;
  transition: color .3s; }

.navbar-nav.action-items .nav-link span.badge {
  position: absolute;
  top: -8px;
  left: 65%; }

.navbar .navbar-nav:not(.action-items) > li:hover,
.navbar .navbar-nav:not(.action-items) > li > a.active {
  background: #112129; }

.navbar .navbar-nav > li:hover i, .navbar .navbar-nav > li > a.active i {
  color: #fff; }

.navbar .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #fff !important; }

.navbar .navbar-nav > .nav-item, .navbar .navbar-nav > .nav-item > .nav-link {
  position: relative; }

.navbar .navbar-nav > .nav-item > .treeview-menu-block {
  display: none !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform-style: preserve-3d; }

.navbar:not(.sidenav) .navbar-nav > li:hover > .treeview-menu-block {
  width: 180px;
  display: block !important;
  position: absolute;
  top: 100%;
  right: 0px; }

.navbar li > .treeview-menu-block > .treeview-menu {
  margin: 0 1px;
  background: #162A35;
  -webkit-box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.4);
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.4); }

.treeview-menu {
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
  padding-left: 5px; }

.treeview-menu > li {
  margin: 0; }

.treeview-menu > li > a {
  padding: 5px 5px 5px 15px;
  display: block;
  font-size: 14px; }

.treeview-menu > li > a > .material-icons {
  width: 20px; }

/*......................................*/
/*................Navbar Search Compoenent......................*/
.navbar-search input {
  border: 1px solid #9a9db7;
  font-size: 12px;
  height: 30px;
  padding: 4px 8px;
  position: absolute;
  width: 50%; }

.navbar-search input:focus {
  outline: none; }

button {
  text-align: center; }

button:focus {
  outline: none; }

button.btn-search, button.btn-reset {
  color: #525672;
  border: none;
  height: 30px;
  font-size: 12px;
  padding: 4px;
  position: absolute;
  width: 30px; }

.navbar-search > button.btn-search > i.material-icons {
  font-size: 20px !important; }

.navbar-search {
  width: 30px;
  height: 30px;
  position: relative; }

.navbar-search input {
  border-radius: 15px;
  transition: all .6s ease-in-out .3s;
  width: 30px;
  right: 0; }

.navbar-search input:focus, .navbar-search input:hover {
  transition-delay: 0;
  width: 200px; }

.navbar-search input:focus ~ button.btn-search, .navbar-search input:focus ~ button.btn-search {
  color: #9a9db7;
  left: 172px;
  transition-delay: 0; }

.navbar-search button, .navbar-search button {
  transition: all .6s ease-in-out; }

.navbar-search button.btn-search, .navbar-search button.btn-search {
  background: #162A35;
  border-radius: 50%;
  height: 30px;
  right: 0px;
  transition-delay: .3s;
  width: 30px;
  border: 1px solid #9a9db7;
  font-size: 20px;
  line-height: 0px;
  padding: 0;
  z-index: 2; }

button.btn.btn-search:hover + input {
  width: 200px; }

/*......................................*/
@media only screen and (min-width: 992px) {
  /*............Navbar No Icon Styling...........*/
  .navbar[class*="no-icon-nav"] .navbar-nav.navbar-navigation-menu > .nav-item > .nav-link > i {
    display: none; }
  .navbar[class*="no-icon-nav"] .navbar-nav.navbar-navigation-menu > .nav-item:after {
    position: absolute;
    content: "";
    height: 15px;
    background-color: #FF485B;
    width: 1px;
    margin-left: 99%;
    top: 38%; }
  /*............Navbar No Icon Styling End...........*/
  /*............Navbar NavItem width Config...........*/
  .navbar[class*="icon-stack"]:not(.sidenav) .navbar-nav > .nav-item, .navbar[class*="no-icon-nav"]:not(.sidenav) .navbar-nav > .nav-item {
    min-width: 108px; }
  /*............NNavbar NavItem width Config End...........*/
  /*............Navbar Height/Spacing Config...........*/
  .navbar[class*="icon-stack"]:not(.sidenav) {
    height: 4rem !important; }
  .navbar[class*="icon-stack"] .navbar-nav > .nav-item > .nav-link:not(.user-avatar) {
    flex-direction: column; }
  /*............Navbar Height/Spacing Config End...........*/
  .navbar.sidenav {
    height: 100%;
    width: 8rem;
    align-items: flex-start;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    z-index: 1500; }
  .navbar.sidenav > .navbar-brand {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #112129;
    justify-content: center;
    padding: 2rem; }
  .navbar.sidenav .navbar-nav {
    flex-direction: column;
    width: 100%; }
  .navbar.sidenav .navbar-collapse {
    align-items: flex-start;
    width: 100%;
    padding-top: 35px; }
  .navbar[class*="icon-stack"].sidenav:not(.side-collapsed) .navbar-nav:not(.action-items) > .nav-item > .nav-link span {
    margin-top: .5rem; }
  .navbar[class*="icon-stack"] .navbar-nav:not(.action-items) .nav-link span.badge {
    position: absolute;
    top: 1px;
    left: 70%; }
  /*.....................................Side Collapsed .................*/
  .navbar.side-collapsed {
    width: 3rem; }
  .navbar.icon-stack.sidenav.side-collapsed .navbar-nav > li:hover > a > span {
    top: 0px;
    margin-left: 10px;
    width: 180px;
    display: flex !important;
    position: absolute;
    left: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 5px; }
  .navbar.icon-stack.sidenav.side-collapsed .navbar-nav > li > a > span {
    margin: 0;
    height: 60%;
    font-size: 14px;
    background: #162A35;
    -webkit-box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.4);
    box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.4); }
  /*................................................*/
  /*..............navbar submenu................*/
  .navbar.icon-stack.sidenav.side-collapsed .navbar-nav > li > a > span {
    display: none !important;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform-style: preserve-3d; }
  .navbar.icon-stack.sidenav:not(.side-collapsed) .navbar-nav:not(.action-items) > li:hover > .treeview-menu-block {
    top: 20px;
    margin-left: 0; }
  .navbar.icon-stack.sidenav .navbar-nav:not(.action-items) > li:hover > .treeview-menu-block {
    width: 180px;
    display: block !important;
    position: absolute;
    left: 100%; }
  /*.....................................*/
  /*............Flexible Navbar.............*/ }

/*................Mobile............*/
@media only screen and (max-width: 992px) {
  .content-wrapper {
    padding-top: 8rem; }
  .navbar .navbar-nav.navbar-navigation-menu {
    position: absolute;
    left: 0;
    top: 3.3rem;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    justify-content: flex-start;
    background: white;
    align-items: flex-start;
    overflow: auto;
    height: auto;
    z-index: 1;
    overflow: hidden; }
  .navbar .navbar-nav.action-items {
    position: absolute;
    top: 3rem;
    left: 0;
    width: 100%;
    height: 3rem;
    flex-direction: row;
    justify-content: flex-end;
    background: #2c2e3e; }
  .navbar-toggler {
    display: none; }
  .user-avatar {
    position: absolute !important;
    bottom: 100%;
    right: -20px; }
  .navbar:not(.sidenav) .navbar-nav:not(.action-items) > .nav-item {
    width: 100%; }
  .navbar:not(.sidenav) .navbar-nav > .nav-item {
    justify-content: flex-start; }
  .navbar:not(.sidenav) .navbar-nav > .nav-item > .treeview-menu-block {
    width: 100% !important;
    padding: 0; }
  .navbar .navbar-nav > .nav-item > .nav-link:not(.user-avatar) span {
    font-size: 0.65rem; } }

/*......................................*/

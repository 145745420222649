/*.........Widget........*/
.widget {
  background-color: transparent;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border: none;
  overflow: hidden; }

.card-header {
  padding: 0.5rem 0rem;
  min-height: 3.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.card-caption {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.card-title {
  display: flex;
  flex-direction: column;
  margin: 0; }

.card-title > .main-title {
  font-size: 1rem; }

/*.........................*/
/*........Card.............*/
.card {
  -webkit-box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08);
  box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08);
  border-radius: .25rem; }

.widget .card-body {
  padding: 0; }

.flat .widget.card {
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-shadow: none;
  -webkit-box-shadow: none; }

.card-caption {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.card-title {
  display: flex;
  flex-direction: column; }

.card-title > .main-title {
  font-size: 1rem;
  font-weight: 600; }

.card-title > .sub-title {
  font-size: 0.85rem; }

.card-caption .card-icon + .card-title {
  margin-left: 5px; }

/*......................*/
/*..................List ...................*/
.list-caption {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.list-title {
  display: flex;
  flex-direction: column; }

.list-title > .main-title {
  font-size: 1rem;
  font-weight: 600; }

.list-title > .sub-title {
  font-size: 0.85rem; }

/*..................................*/
/*............Action Buttons.................*/
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: center; }

.action-buttons > .btn-circle {
  height: 27px;
  width: 27px; }

.action-buttons > .btn:hover {
  background-color: #f2f2f2; }

.action-buttons > .btn {
  margin-left: 5px; }

.action-buttons > .btn > i {
  font-size: 14px; }

/*....................................*/
